import React from 'react';
import MainLayout from '../components/MainLayout';
import Row from 'react-bootstrap/Row';
import '../css/Press.css';
import Col from 'react-bootstrap/Col';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

/**
 * Press page
 */
class Press extends React.Component {

    /**
	 * Constructor
	 * @param {*} props Component props
	 */
	constructor(props) {

		super(props);

		this.previous = this.previous.bind(this);

		this.next = this.next.bind(this);

		this.onResize = this.onResize.bind(this);

		this.onSlideChanged = this.onSlideChanged.bind(this);

        // Ref to the press release year carousel
		this.carousel = React.createRef();

		let data = this.props.data.allKontentItemPresspage.nodes[0].elements;

        // order press releases by year
		let tabPressReleases = [];
		let objPressReleases = {};

		for (let i = 0; i < data.press_releases.value.length; i++) {
			let pressReleaseData = data.press_releases.value[i];
			let year = pressReleaseData.elements.date.value.split('-')[0];

			let pressRelease = {
				year: year,
				date: pressReleaseData.elements.date.value,
				title: pressReleaseData.elements.title.value,
				document: pressReleaseData.elements.document.value[0].url
			};

			if (!objPressReleases[year]) {
				objPressReleases[year] = [];
			}

			objPressReleases[year].push(pressRelease);
		}

		for (let year in objPressReleases) {
			let yearObj = {
				year: year,
				releases: objPressReleases[year]
			};

			tabPressReleases.push(yearObj);
		}

		tabPressReleases.sort((a, b) => {
			return parseInt(b.year) - parseInt(a.year);
		});

		this.state = {
            // is pressrelease year carousel previous button active
            isPrevActive: false,
            // is pressrelease year carousel next button active
            isNextActive: true,
            // pressrelease year carousel current index
            currentIndex: 0,
            // pressrelease year carousel nb items per slide
            itemsInSlide: 12,
            // pressreleases tab
            tabPressReleases: tabPressReleases,
            // pressrelease selected year
			yearSelectedIndex: 0
		};
	}

    /**
	 * Go to previous pressrelease yearcarousel page
	 */
	previous() {
		if (this.state.isPrevActive) {
			let decrement = this.state.itemsInSlide;

			if (this.state.currentIndex - this.state.itemsInSlide < 0) {
				decrement = this.state.currentIndex;
			}

			this.carousel.current.slideTo(this.state.currentIndex - decrement);

			if (this.state.currentIndex - decrement > 0) {
				this.setState({
					isPrevActive: true
				});
			}
			else {
				this.setState({
					isPrevActive: false
				});
			}

			this.setState({
				isNextActive: true
			});

			this.setState({
				currentIndex: this.state.currentIndex - decrement
			});
		}
	}

    /**
	 * Go to next pressrelease yearcarousel page
	 */
	next() {
		if (this.state.isNextActive) {
			let nbElements = this.state.tabPressReleases.length;

			let increment = this.state.itemsInSlide;

			if (this.state.currentIndex + this.state.itemsInSlide + this.state.itemsInSlide >= nbElements) {
				increment = nbElements - this.state.currentIndex - this.state.itemsInSlide;
			}

			this.carousel.current.slideTo(this.state.currentIndex + increment);

			if (this.state.currentIndex + increment + this.state.itemsInSlide < nbElements) {
				this.setState({
					isNextActive: true
				});
			}
			else {
				this.setState({
					isNextActive: false
				});
			}

			this.setState({
				isPrevActive: true
			});

			this.setState({
				currentIndex: this.state.currentIndex + increment
			});
		}

		/**/
	}

    
	/**
	 * Update state on resize 
	 * @param {CarouselEvent} event Carousel resize event
	 */
	onResize(event) {
		let nbElements = this.state.tabPressReleases.length;
		let { itemsInSlide, item } = event;
		let prevActive = item > 0;
		let nextActive = item + itemsInSlide < nbElements;

		if (item + itemsInSlide >= nbElements) {
			item = nbElements - itemsInSlide;

			prevActive = item > 0;
			nextActive = false;
		}

		let newState = {
			itemsInSlide: itemsInSlide,
			currentIndex: item,
			isNextActive: nextActive,
			isPrevActive: prevActive
		};

		this.setState(newState);
	}

    /**
     * Set selected year
     * @param {int} yearIndex Selected year index
     */
	selectYear(yearIndex) {
		this.setState({
			yearSelectedIndex: yearIndex
		});
	}

    /**
	 * Update state on carousel slide change
	 * @param {CarouselEvent} event Carousel slidechanged event
	 */
	onSlideChanged(event) {
		this.setState({ currentIndex: event.item });
		this.onResize(event);
	}

	render() {
		let data = this.props.data.allKontentItemPresspage.nodes[0].elements;

        /* Number of items displayed in pressrelease year carousel based on screen width */
		let responsive = {
			0: {
				items: 2
			},
			515: {
				items: 4
			},
			700: {
				items: 6
			},
			850: {
				items: 8
			},
			1050: {
				items: 10
			},
			1400: {
				items: 12
			}
		};

		let nextButtonClass = '';
		if (!this.state.isNextActive) {
			nextButtonClass = ' arrow-disabled';
		}

		let prevButtonClass = '';
		if (!this.state.isPrevActive) {
			prevButtonClass = ' arrow-disabled';
		}

		return (
			<MainLayout setNewState={this.setNewState} scrollSnap={false} className="press-container">
                {/* TITLE */}
				<Row className="press-marginbottom">
					<Col xs={{ span: 10, offset: 1 }}>
						<div className="emg-title">{data.title.value}</div>
						<div className="press-margintop emg-text" dangerouslySetInnerHTML={{ __html: data.intro.value }} />
					</Col>
				</Row>
                {/* INTRO */}
				<Row className="press-marginbottom">
					<Col xs={{ span: 10, offset: 1 }}>
						<div className="emg-title">{data.contact_title.value}</div>
						<div className="press-contact-text press-margintop emg-text" dangerouslySetInnerHTML={{ __html: data.contact_text.value }} />
					</Col>
				</Row>
                {/* MEDIA */}
				<Row className="press-marginbottom">
					<Col xs={{ span: 10, offset: 1 }}>
						<div className="emg-title">{data.media_title.value}</div>
                        {/* LOGOS */}
						<div className="press-margintop emg-title">01. {data.media_logo_title.value}</div>
						<div className={'emg-yellow-background emg-accordion-line'} />
						<div className="press-logos-container">
							{data.media_logos.value.map((logo, index) => (
								<div className="about-team-member" key={'press-logo-' + index}>
									<div className="about-team-member-container">
										<a href={logo.url} target="_blank" rel="noreferrer" className="about-team-member-picture-container">
											<div className="about-team-member-picture-container-inside">
												<img className="about-team-member-picture" src={logo.url} alt="logo" />
											</div>
											<div className="download-hover" />
										</a>
										<div className="press-logos-extension emg-text">{logo.url.split('.').pop()}</div>
									</div>
								</div>
							))}
						</div>
                        {/* HEADSHOTS */}
						<div className="press-margintop emg-title">02. {data.media_headshots_title.value}</div>
						<div className={'emg-yellow-background emg-accordion-line'} />
						<div className="press-headshots-container">
							{data.media_headshots.value.map((member, index) => (
								<div className="about-team-member" key={'press-headshot-' + index}>
									<div className="about-team-member-container">
										<a
											href={member.elements.picture.value[0].url}
											target="_blank"
											rel="noreferrer"
											className="about-team-member-picture-container">
											<img
												className="about-team-member-picture"
												src={member.elements.picture.value[0].url}
												alt={member.elements.name.value}
											/>
											<div className="download-hover" />
										</a>
										<div className="about-team-member-name">{member.elements.name.value}</div>
										<div className="emg-text">{member.elements.role.value}</div>
									</div>
								</div>
							))}
						</div>
					</Col>
				</Row>
                {/* PRESS RELEASES */}
				<Row className="press-marginbottom">
					<Col xs={{ span: 10, offset: 1 }}>
						<div className="press-margintop emg-title">{data.press_releases_title.value}</div>
						<div className="press-margintop press-releases-year-carousel-container">
							<div className="press-releases-year-carousel">
								<AliceCarousel
									autoHeight={false}
									startIndex={this.state.currentIndex}
									infinite={false}
									duration={500}
									dotsDisabled={true}
									buttonsDisabled={true}
									ref={this.carousel}
									responsive={responsive}
									onInitialized={this.onResize}
									onResized={this.onResize}
									onSlideChanged={this.onSlideChanged}>
									{this.state.tabPressReleases.map((year, index) => (
										<div
											onClick={(e) => this.selectYear(index)}
											className={
												this.state.yearSelectedIndex === index ? (
													'press-release-year-selected press-releases-year-text'
												) : (
													'press-releases-year-text'
												)
											}
											key={'press-releases-year-' + index}>
											{year.year}
											<div className="press-releases-year-selected-line" />
										</div>
									))}
								</AliceCarousel>
							</div>
							<div className="timeline-controls-container">
								<div className={'timeline-arrow' + prevButtonClass} onClick={this.previous}>
									<svg xmlns="http://www.w3.org/2000/svg" width="43" height="43" viewBox="0 0 51.326 51.326">
										<path
											className="timeline-arrow-svg"
											fill="none"
											stroke="#fff"
											strokeLinecap="round"
											strokeLinejoin="round"
											strokeWidth="5px"
											d="M0 30.213l24.08-6.133L30.213 0"
											opacity="1.0"
											transform="rotate(135 18.199 22.571)"
										/>
									</svg>
								</div>
								<div className="timeline-controls-separator" />
								<div className={'timeline-arrow' + nextButtonClass} onClick={this.next}>
									<svg xmlns="http://www.w3.org/2000/svg" width="43" height="43" viewBox="0 0 51.326 51.326">
										<path
											className="timeline-arrow-svg"
											fill="none"
											stroke="#ffffff"
											strokeLinecap="round"
											strokeLinejoin="round"
											strokeWidth="5px"
											d="M0 30.213l24.08-6.133L30.213 0"
											transform="rotate(-45 33.127 7.642)"
										/>
									</svg>
								</div>
							</div>
						</div>
						<div className="press-margintop">
							{this.state.tabPressReleases[this.state.yearSelectedIndex].releases.map((release, index) => (
								<div className="press-release-line-container" key={'press-release-' + index}>
									<div className="press-release-line-date-container">
										<div className="press-release-line-title">{release.title}</div>
										<div className="press-release-line-date">{release.date.split('T')[0]}</div>
									</div>

									<a href={release.document} target="_blank">
										{' '}
										<div className="press-release-line-download" />
									</a>
								</div>
							))}
						</div>
					</Col>
				</Row>
			</MainLayout>
		);
	}
}

export default Press;

export const query = graphql`
	query Press {
		allKontentItemPresspage {
			nodes {
				elements {
					contact_text {
						value
					}
					contact_title {
						value
					}
					title {
						value
					}
					intro {
						value
					}
					media_title {
						value
					}
					media_logos {
						value {
							type
							url
						}
					}
					media_headshots_title {
						value
					}
					media_logo_title {
						value
					}
					press_releases {
						value {
							... on kontent_item_pressrelease {
								elements {
									date {
										value(locale: "en")
									}
									document {
										value {
											url
										}
									}
									title {
										value
									}
								}
							}
						}
					}
					press_releases_title {
						value
					}
					media_headshots {
						value {
							... on kontent_item_teammember {
								elements {
									name {
										value
									}
									role {
										value
									}
									picture {
										value {
											url
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
`;
